<template>
  <section>
    <title-section
      :title="titleSelection"
      :modeEdit="true"
      returnText="Regresar"
      :returnToFunction="returnPrevView"
      @set-value="setValue"
    >
      <template>
        <div class="level-item">
          <button
            class="button button_micro_dark_outlined"
            :disabled="loadingBtn"
            @click="returnPrevView"
          >
            CANCELAR
          </button>
        </div>
        <div class="level-item">
          <button
            class="button button_micro_dark"
            :class="{ 'is-loading' : loadingBtn }"
            :disabled="loadingBtn"
            @click="createSelectionGuideAction"
          >
            CREAR
          </button>
        </div>
      </template>
    </title-section>
    <template v-if="loadingProd">
      <Loading />
    </template>
    <template v-else>
      <selection-guide-main-detail
        ref="selectionAdd"
        :loadingProd="loadingProd"
        :guidesProductList="guidesProduct"
      />
    </template>
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SelectionGuideAdd',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    SelectionGuideMainDetail: () => import('@/components/SelectionGuide/Add/SelectionGuideMainDetail.vue'),
    Loading: () => import('@/components/UI/Loading.vue')
  },
  data () {
    return {
      loadingBtn: false,
      loadingProd: false,
      titleSelection: null,
      guidesProduct: []
    }
  },
  methods: {
    ...mapActions(['returnPrevView', 'getProductsGuide', 'createSelectionGuide']),
    setValue (event) {
      this.titleSelection = event.value
    },
    async createSelectionGuideAction () {
      if (await this.$validator.validateAll()) {
        this.loadingBtn = true
        const dataNewSelectionGuide = await this.$refs.selectionAdd.getDataContruct()
        const { localDescription, localStatus } = dataNewSelectionGuide.generalData
        dataNewSelectionGuide.guideName = this.titleSelection
        dataNewSelectionGuide.description = localDescription
        dataNewSelectionGuide.active = localStatus
        console.log(dataNewSelectionGuide)
        const resp = await this.createSelectionGuide(dataNewSelectionGuide)
        console.log(resp)
        if (resp) {
          this.returnPrevView()
        }
        this.loadingBtn = false
      }
    }
  },
  async beforeMount () {
    this.loadingProd = true
    const { success, guides } = await this.getProductsGuide()
    if (success) { this.guidesProduct = guides }
    this.loadingProd = false
  }
}
</script>

<style lang="scss" scoped>
  .button {
    font-size: 14px;
    width: 150px;
  }
</style>
